/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
	      
	      $(window).load(function(){
		      $('.slick-current').trigger( "click" );
		      $('.gallery').addClass('active');
		      if($(window).outerWidth() > 768){
		      
			      var height_col = 0;
			      var height_title_wrap = 0;
			      
			      $('.col-height').each(function(){
				      if($(this).outerHeight() > height_col){
					      height_col = $(this).outerHeight();
				      }
				      
				      if($(this).find('.title_product_wrap .entry-title').outerHeight() > height_title_wrap){
					      height_title_wrap = $(this).find('.title_product_wrap .entry-title').outerHeight();
				      }
			      });
			      
			      $('.col-height').height(height_col);
			      $('.col-height').find('.title_product_wrap .entry-title').height(height_title_wrap);
		      
					}
	      });
	      
	      var opening_menu = false;
	      $('.mobile_icon').on('click', function(){
	        opening_menu = true;
	        if($('.nav-primary').hasClass('active')){
	        	$('.nav-primary').removeClass('active');
	        	$('body').removeClass('active');
	        }else{
	        	$('.nav-primary').addClass('active');
	        	$('body').addClass('active');
	        	window.setTimeout(function(){ opening_menu = false; }, 1);
			}
          });
        
		  setTimeout(function(){ jQuery('.current-cat').parent().parent().addClass('current_page_item'); }, 100);
				        
        $('body').click(function(event){
	        if(!opening_menu){
	        	var target = $( event.target );					
						if ( !target.is( "nav.active" ) ){
							$('.nav-primary').removeClass('active');
							$('body').removeClass('active');
						}
					}
        });
	      
        $('.tabs > a').on('click', function(){
	        
	        $('.tabs > a').removeClass('active');
	        $('.tabs_content > div').removeClass('active');

	        $('.tabs_content > div' + '.' + $(this).attr('class')).addClass('active');
	        $(this).addClass('active');
	        
        });
        
        
        $('.video_link').on('click', function(){
	        $('.popup_video').addClass('active');
        });
        
        $('.popup_video').on('click', function(){
	        $('.popup_video').removeClass('active');
        });
        
        $('.featured_image').on('click', function(){
	        $('.popup_wrap').show();
        });
        
        $('.featured_image.init').on('click', function(){
	        $('.popup_wrap').show();
        });
        
        function closePopup(){
	        $('.popup_wrap').hide();
        }
        
        $(document).keyup(function(e) {
		    if (e.keyCode == 27) { // escape key
		        closePopup();
		    }		    
		});
		
		var next_index = -1;
		
		$('.popup_wrap').on('click', function(){
			closePopup();
		});
		
		
		$('.popup_image').on('click', function(e){			
			var total = $('.slick-slide').length - 1;
			
			if(next_index == -1){
				if($('.slick-slide.active')){
					console.log('oui');
					next_index = $('.slick-slide').index($('.slick-slide.slick-current'));
				}else{
					console.log('non');
					next_index = $('.slick-slide').index($('.slick-slide.active'));
				}
				next_index++;
			}else{
				next_index++;
				if(next_index > total){
					next_index = 0;
				}
			}
									
			
			$('.slick-slide').removeClass('active');
			$('.slick-slide').eq(next_index).addClass('active');
			
			
			var large = $('.slick-slide').eq(next_index).attr('large');
			
			$(this).attr('src', large);
			return false;
		});
        
        $('.gallery').slick({
		  infinite: false,
		  slidesToShow: 5,
		  slidesToScroll: 1
		});
		
		function getMeta(url, callback) {
		    var img = new Image();
		    img.src = url;
		    img.onload = function() { callback(this.width, this.height); }
		}
		
		
		var slickTrackCount = 0;
		$('.slick-track a').on('click', function(){
			slickTrackCount++;
			$('.slick-track a').removeClass('active');
			var image = $(this).attr('large');
			var d = new Date();
			var largeImage = $(this).attr('large') + '?' + d.getTime();

			$(this).addClass('active');
	        $('.popup_image').attr('src', image);
			$('.featured_image img').attr('srcset', '');
			$('.featured_image img').attr('src', largeImage);
			
			/*
			$('.featured_image img').attr('data-zoom-image', largeImage);
			var image_height = '';
			
			var img = new Image();
		    img.addEventListener("load", function(){
		        image_height = this.naturalHeight;
		        
		        if(slickTrackCount > 1){
					jQuery(".zoomWindowContainer").find('div').css('background-image', 'url(' + largeImage + ')');
					jQuery(".zoomContainer").css('height', image_height + 'px');
					jQuery(".zoomWindowContainer").css('height', image_height + 'px');
					jQuery(".zoomWindowContainer").find('div').css('height', image_height + 'px');
				}
		        
		    });
		    img.src = largeImage;
			
			
			*/
			
			
		});
	      
	      
	      var allow_update = true;
				var pagination = 1;
				
				$(window).scroll(function() {

			   if($(window).scrollTop() + $(window).height() > ($(document).height()/2) && allow_update) {
			      
				      allow_update = false;
				      $('.ajax_loader').show();
				      jQuery.post(
								admin_url, 
						    {
						      'action' : 'add_foobar',
						      'pagination' : pagination,
						      'cat' : cat
						    }, 
						    function(response){
					        $('.ajax_loader').hide();
					        
					        if(response === ''){
						        
					        }else{
						        $('.product_row').append(response);
						        allow_update = true;	
										pagination++;
																
										var height_col = 0;
							      var height_title_wrap = 0;
							      
							      $('.col-height').each(function(){
								      if($(this).outerHeight() > height_col){
									      height_col = $(this).outerHeight();
								      }
								      
								      if($(this).find('.title_product_wrap').outerHeight() > height_title_wrap){
									      height_title_wrap = $(this).find('.title_product_wrap').outerHeight();
								      }
							      });
							      
							      $('.col-height').height(height_col);
							      $('.col-height').find('.title_product_wrap').height(height_title_wrap);
										
					        }					        
						    }
							); 
				   }
					});

        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
  
      }
    },
    'contact': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
	      
        var form_available = true;
					
					$('.contact_form .submit').click(function(){
						$('.message_status').hide();
						if(!form_available){
							return false;
						}else{
							form_available = false;
						}
						
						$(".contact_form :input").attr("disabled", true);
						$(this).addClass('waiting');
						
						var data = {
							'action': 'mymail',
							'name': $('.contact_form').find('input[name="name"]').val(),
							'email': $('.contact_form').find('input[name="email"]').val(),
							'telephone': $('.contact_form').find('input[name="telephone"]').val(),
							'subject': $('.contact_form').find('input[name="subject"]').val(),
							'message': $('.contact_form').find('textarea[name="message"]').val(),
							'lang': $('.contact_form').find('input[name="lang"]').val(),
							'address': $('.contact_form').find('input[name="address"]').val(),
							'sdfkjhsdf': encoded_email
						};
						
						var admin_url = ajax_url;
								
						$.post( admin_url, data)
					  .done(function( result ) {
						  var message = '';
						  
						  if(result === 'sent'){
							   message = success;
							   $('.contact_form')[0].reset();
						  }
						  if(result === 'fail_email'){
							  message = fail_email_message;
						  }
						  
						  if(result === 'fail'){
							  message = fail_message;
						  }
						  
						  $(".contact_form :input").attr("disabled", false);
						  $('.waiting').removeClass('waiting');
				
					    $('.message_status').html(message);
					    $('.message_status').show();
					    form_available = true;
					  });
					});
        
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
